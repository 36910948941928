/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

.hidden {
  display: none;
}

@font-face {
  font-family: "Raleway"; //This is what we are going to call
  src: url("./assets/fonts/Raleway-Regular.ttf");
}

* {
  font-family: "Raleway", sans-serif;
  --color: #616161;
}

mat-drawer:hover {
  side-nav {
    p {
      opacity: 1;
      margin: auto 10px;
      transition: all 0.1s ease-in 0.2s;
    }
  }
}

.body {
  height: calc(100vh - 80px);
}

.main-content {
  padding: 40px;
}

.ngx-mat-timepicker form {
  min-width: 25px !important;
  font-size: 17px !important;
}

.mdc-icon-button svg, .mdc-icon-button img {
  display: unset !important;
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0) !important;
}

mat-form-field {
  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-focus-overlay {
    background-color: white !important;
  }
}

.select-rounded {
  .mat-mdc-text-field-wrapper {
    border-radius: 1.5rem;
    height: 44px !important;
  }

  .mdc-floating-label {
    padding-bottom: 12px;
  }

  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-75%) scale(0.5);
  }

  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
  .mat-mdc-form-field-infix {
    padding-top: 12px !important;
  }

  .mdc-floating-label--float-above,
  .mat-mdc-select-value-text {
    padding-left: 28px;
  }
}

.message-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

@media (min-width: 1200px) {
  .message-overlay {
    display: block;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.mat-dialog-border-radius .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(--mdc-dialog-container-shape, var(--mdc-shape-medium, 25px));
}

.btn-primary-simskills {
  @apply w-[184px] h-[38px] bg-[#F2B23E] rounded-[10px] col-span-1 text-center text-zinc-700 text-[15px] font-medium leading-none;
}
